<template>
    <ul class="mainmenu">
        <li>
            <a href="#Inicio">
                Inicio
            </a>
        </li>
        <li>
            <a href="#Nosotros">
                Nosotros
            </a>
        </li>
        <li>
            <a href="#Flujo de trabajo">
                Flujo de trabajo
            </a>
        </li>
        <li>
            <a href="#Campana">
                Campaña
            </a>
        </li>
        <li>
            <a href="#Contacto">
                Contacto
            </a>
        </li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>