<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse${content.id}`"
                            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                            :aria-controls="`collapse${content.id}`"
                            @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon"/>
                    </button>
                </h2>
                <div :id="`collapse${content.id}`"
                     class="accordion-collapse collapse"
                     :class="{'show': index === 0}"
                     :aria-labelledby="`heading${content.id}`"
                     :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body">
                        {{ content.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            id: {
                type: String,
                default: 'accordionExample'
            },
            accordionStyleClass: {
                type: String
            },
            accordionContent: {
                type: Array,
                default: function () {
                    return [
                        {
                            id: 'One',
                            title: '¿Cómo funciona la campaña?',
                            description: `Una campaña de salud visual es una 
                                iniciativa que busca mejorar la salud ocular. 
                                Ofrece exámenes de la vista completamente gratis, distribuye lentes, 
                                educa sobre el cuidado ocular y previene enfermedades oculares.
                                Estas campañas colaboran con profesionales, se centran en comunidades/empresas
                                y evalúan su impacto para garantizar una visión más saludable..`
                        },
                        {
                            id: 'Two',
                            title: '¿Qué precio tiene?',
                            description: `No cobramos absolutamente nada por llevar a cabo una campaña en su empresa o comunidad.
                                El examen de la vista es completamente GRATIS sin ningún compromiso a compra.`
                        },
                        {
                            id: 'Three',
                            title: '¿Qué diferencia de comprar lentes en otro lugar?',
                            description: `Ofrecemos un precio mucho más bajo del que manejan las ópticas, un trato personalizado y una garantía.
                                Estos beneficios no los limitamos a la campaña. El beneficio se extiende a toda la familia de cualquiera de sus
                                colaboradores incluyendo en fechas diferentes a las de la campaña.`
                        },
                        {
                            id: 'Four',
                            title: '¿Garantía?',
                            description: `El proceso de adaptación en un lente puede durar hasta 1 mes.
                                En caso de que no se haya adaptado en este tiempo se tiene un contacto nuevamente,
                                hacemos nuevamente el examen de la vista y cambiamos el lente sin ningún costo.
                                En otro caso donde el lente sea el apto para la persona pero haya un defecto de fábrica 
                                en el armazón, este se cambia sin ningún costo, aplica para los primeros 6 meses con un uso
                                normal del lente.`
                        },
                    ]
                }
            }
        },
        data() {
            return {
                activeCollapse: 0
            }
        }
    }
</script>