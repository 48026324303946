<template>
    <Layout header-class="header-left-align" nav-alignment="left" :footer-style="2" buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div id="Inicio" class="slider-area slider-style-3 height-850">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient" data-aos="fade-up" data-aos-delay="100">Enfoque <br/> Claro
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="130">
                                Potenciamos la visión de tus colaboradores, 
                                impulsando la claridad y el bienestar n tu empresa. 
                                ¡Un futuro más saludable y enfocado comienza aquí!
                            </p>
                        </div>
                    </div>
                    <div class="order-1 order-lg-2 col-lg-5" data-aos="fade-up" data-aos-delay="100">
                        <div class="thumbnail rounded">
                            <img src="../../assets/images/EnfoqueClaro/Lentes.png" alt="Banner Images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator id="Nosotros"/>

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="¿Enfoque Claro?"
                            title="Acerca de Nosotros"
                            description="Somos una empresa dedicada a hacer campañas de salud visual en empresas y comunidades"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Tab :tab-data="tabData"/>
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator id="Flujo de trabajo"/>

        <!-- Start Timeline Area -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="¿Cómo es el proceso?"
                            title="Flujo de trabajo"
                            description="Nuestro proceso inicia en el primer contacto y termina cuando nuestro cliente queda satisfecho"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 mt--50">
                        <Timeline :timeline-data="timelineData" :timeline-style="4"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Timeline Area -->

        <Separator id="Campana"/>

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/EnfoqueClaro/cajaLentes.jpg" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <SectionTitle
                                subtitle="IMPLEMENTACIÓN CAMPAÑA"
                                title="Campaña de salud visual"
                                data-aos="fade-up"
                            />
                            <accordion
                                id="accordionExampleOne"
                                data-aos="fade-up"
                                data-aos-delay="60"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->


        <Separator id="Contacto"/>

         <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            subtitle="Contacto"
                            title="Te proporcionamos nuestro contacto"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="message-circle" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">WhatsApp</h4>
                                            <p><a href="https://wa.me/524611912989">461-191-2989</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">Correo Electrónico</h4>
                                            <p><a href="mailto:atencion@enfoqueclaro.com">atencion@enfoqueclaro.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15">
                    <div class="col-lg-7">
                        <ContactForm/>
                    </div>
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->

    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Tab from '../../components/elements/tab/Tab'
    import Timeline from '../../components/elements/timeline/Timeline'
    import Accordion from '../../components/elements/accordion/Accordion'
    import Pricing from '../../components/elements/pricing/Pricing'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import BlogPost from '../../components/blog/BlogPost'

    export default {
        name: 'Business',
        components: {BlogPost, Pricing, Accordion, Timeline, Tab, SectionTitle, Separator, Icon, Layout},
        mixins: [BlogPostMixin],
        data() {
            return {
                tabData: {
                    tabImage: 'lentesMesa.png',
                    tabContent: [
                        {
                            id: 1,
                            menu: 'Estrategia',
                            content: `<p>Nuestra estrategia se enfoca en llevar una visión saludable 
                                y accesible a todas las comunidades y empresas del Bajío. A través de 
                                campañas de salud visual y un equipo de especialistas altamente calificados,
                                nos comprometemos a proporcionar atención de calidad y
                                educación sobre la importancia de la salud visual. 
                                Nuestra misión es empoderar a las personas para que tomen el control de su 
                                visión y disfruten de una vida más clara y brillante en el Bajío</p>`,
                        },
                        {
                            id: 2,
                            menu: 'Desarrollo',
                            content: `<p>Nuestras campañas de salud visual se basan en un enfoque integral. 
                                Combinamos revisiones oculares de calidad con GARANTÍA, educación sobre la importancia 
                                de la salud visual y la distribución de recursos visuales asequibles. 
                                Nuestro objetivo es proporcionar una atención completa y accesible para
                                mejorar la visión de las comunidades en el Bajío</p>`,
                        },
                        {
                            id: 3,
                            menu: 'Garantía',
                            content: `<p>Nuestra dedicación a la salud visual no termina en la revisión 
                                ocular. Nos enorgullece ofrecer una sólida garantía de calidad en todos los
                                lentes que proporcionamos. Cada par de lentes que entregamos está respaldado 
                                por nuestra promesa de satisfacción del cliente. Si experimentas cualquier
                                problema o insatisfacción con tus lentes dentro del período de garantía, 
                                estaremos aquí para corregirlo. Tu visión es valiosa para nosotros, y queremos
                                asegurarnos de que siempre veas el mundo con claridad y comodidad. </p>`,
                        }
                    ]
                },
                timelineData: [
                    {
                        id: '1',
                        title: 'Contacto',
                        description: 'Contactamos al departamento de RH, salud o algún otro correspondiente.',
                    },
                    {
                        id: '2',
                        title: 'Acuerdo/Difusión',
                        description: 'Acordamos fecha, hora y lugar. Así como la empresa inicia con la difusión',
                    },
                    {
                        id: '3',
                        title: 'Campaña',
                        description: 'Nuestros expertos acuden a la revisión de sus colaboradores.',
                    },
                    {
                        id: '4',
                        title: 'Entrega',
                        description: 'Acordamos la fecha de entrega de lentes para sus colaboradores.',
                    },
                    
                ],
            }
        }
    }
</script>